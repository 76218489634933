<template>
  <b-modal
    :id="'show-publish-success-url'+slug"
    no-close-on-backdrop
    centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.showPublishSuccess.title') }}</h3>
    </template>
    <div class="show-url-icon text-center px-2">
      <b-icon icon="check-circle" variant="success" class="mt-5 mb-4"></b-icon>
      <p class="mt-2 mb-4"
           v-html="$t('modals.showPublishSuccess.messageOne', { pageType: pageType })">
      </p>
      <div class="mb-4">
        <p class="mb-0"
           v-html="$t('modals.showPublishSuccess.messageTwo', { pageType: pageType })"></p>
        <div class="mb-4 px-2">
        <p class="mb-4"><a class="show-url" :href="publishingUrl"
          target="_blank"><u>{{ publishingUrl }}</u></a></p>
          <b-button v-b-toggle.collapse-2 class="m-1 light-grey-button">
            {{ $t('modals.qrCode.button') }}</b-button>
          <!-- Element to collapse -->
          <b-collapse id="collapse-2">
            <div class="pt-3">
                  <p><img :src="`https://api.qrserver.com/v1/create-qr-code/?size=120x120&data=${publishingUrl}`" alt=""></p>
                  <p><a :href="`https://api.qrserver.com/v1/create-qr-code/?format=svg&data=${publishingUrl}&download=1`">{{ $t('modals.qrCode.svg') }}</a>
                  <br>
                  <a :href="`https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&format=jpg&data=${publishingUrl}&download=1`">{{ $t('modals.qrCode.jpg') }}</a></p>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <template #modal-footer="{ ok }">
      <b-button
        class="p-3"
        block
        @click="closeModal"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalShowPublishSuccess',
  props: ['slug', 'groupSlug', 'slugPath', 'pageType', 'groupDomain'],
  data: () => ({
    publishingUrl: null,
  }),
  created() {
    this.publishingUrl = `${this.groupDomain}${this.slugPath}`;
    // if project add / at the end
    if (this.pageType === 'project') {
      this.publishingUrl = `${this.publishingUrl}/`;
    }
  },
  mounted() {
    this.$bvModal.show(`show-publish-success-url${this.slug}`);
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(`show-publish-success-url${this.slug}`);
      this.$emit('modal-confirmed');
    },
  },
};
</script>

<style scoped lang="scss">
.show-url-icon svg {
  font-size: 60px;
}
.show-url {
  font-size: 1.1rem;
}
</style>
