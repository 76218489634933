var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "show-publish-success-url" + _vm.slug,
        "no-close-on-backdrop": "",
        centered: ""
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _c("h3", [
                _vm._v(_vm._s(_vm.$t("modals.showPublishSuccess.title")))
              ])
            ]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function({ ok }) {
            return [
              _c(
                "b-button",
                {
                  staticClass: "p-3",
                  attrs: { block: "" },
                  on: { click: _vm.closeModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "show-url-icon text-center px-2" },
        [
          _c("b-icon", {
            staticClass: "mt-5 mb-4",
            attrs: { icon: "check-circle", variant: "success" }
          }),
          _c("p", {
            staticClass: "mt-2 mb-4",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("modals.showPublishSuccess.messageOne", {
                  pageType: _vm.pageType
                })
              )
            }
          }),
          _c("div", { staticClass: "mb-4" }, [
            _c("p", {
              staticClass: "mb-0",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("modals.showPublishSuccess.messageTwo", {
                    pageType: _vm.pageType
                  })
                )
              }
            }),
            _c(
              "div",
              { staticClass: "mb-4 px-2" },
              [
                _c("p", { staticClass: "mb-4" }, [
                  _c(
                    "a",
                    {
                      staticClass: "show-url",
                      attrs: { href: _vm.publishingUrl, target: "_blank" }
                    },
                    [_c("u", [_vm._v(_vm._s(_vm.publishingUrl))])]
                  )
                ]),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.collapse-2",
                        modifiers: { "collapse-2": true }
                      }
                    ],
                    staticClass: "m-1 light-grey-button"
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("modals.qrCode.button")))]
                ),
                _c("b-collapse", { attrs: { id: "collapse-2" } }, [
                  _c("div", { staticClass: "pt-3" }, [
                    _c("p", [
                      _c("img", {
                        attrs: {
                          src: `https://api.qrserver.com/v1/create-qr-code/?size=120x120&data=${_vm.publishingUrl}`,
                          alt: ""
                        }
                      })
                    ]),
                    _c("p", [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `https://api.qrserver.com/v1/create-qr-code/?format=svg&data=${_vm.publishingUrl}&download=1`
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("modals.qrCode.svg")))]
                      ),
                      _c("br"),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&format=jpg&data=${_vm.publishingUrl}&download=1`
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("modals.qrCode.jpg")))]
                      )
                    ])
                  ])
                ])
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }